<script>
import stepMixin from "../../mixins/stepMixin";
import checkCart from "../../mixins/checkCart";

import { $get } from "../../http/index";
import { mapActions, mapGetters, mapState } from "vuex";
import CarouselComponent from "../../components/CarouselComponent";

export default {
  mixins: [stepMixin, checkCart],
  components: {
    ProductCard: () =>
      import(/* webpackChunkName: "ProductCard" */ `../../components/ProductCard`),
    CarouselComponent,
  },
  data() {
    return {
		windowHeight: window.innerWidth
    };
  },

  computed: {
    year() {
      let date = new Date();
      return date.getFullYear();
    },
    ...mapGetters({
      hotdogs: "hotdogs",
    }),
    display_no_office_hours_image() {
      let formmatedTime = new Date();
      let day = new Date(formmatedTime).getDay();
      let hours = new Date(formmatedTime).getHours();
      let minutes = new Date(formmatedTime).getMinutes();
	  let display=false;
	  //test variables 
	  //hours=11;
	  //minutes=0;
      if (day == 6) {
        display= true;
      } else if (hours < 11) {
        display= true;
      }else if (hours >= 19 && minutes >= 30) {
        display= true;
      } else if (hours == 15 && minutes >= 0&& minutes <= 59) {
        display= true;
      } else if (hours == 16 && minutes >= 0&& minutes < 30  ) {
        display= true;
      } 
	  return display
    },
	is_mobile_screen(){
		if(this.windowHeight<1000){
			return true
		}else{
			return false
		}
	}
  },
  mounted() {
	this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
    if (!this.hotdogs.length) {
      this.getMenu("hotdogs");
      this.getMenu("sides");
      this.getMenu("drinks");
    };
  },
  beforeDestroy() { 
    window.removeEventListener('resize', this.onResize); 
  },
  methods: {
	onResize() {
      this.windowHeight = window.innerWidth
    },
    ...mapActions({
      getMenu: "getMenu",
    }),
  },
};
</script>
<template>
  <div class="section">
    <div v-if="display_no_office_hours_image">
      <img
        v-if="is_mobile_screen"
        src="../../assets/img/out_office_mobile.jpg"
        style="width: 100%"
        alt=""
        srcset=""
      />
      <img
        v-else
        src="../../assets/img/out_office_pc.jpg"
        style="width: 100%"
        alt=""
        srcset=""
      />
    </div>
    <div v-else>
      <div class="alert">
        <div class="section__container py-2">
          <h5 class="text-center m-0 text-bold">Selecciona tus panshitos - Paso 1/4</h5>
        </div>
      </div>
      <div class="d-none d-md-block">
        <CarouselComponent :size="hotdogs.length">
          <div class="carousel__well">
            <div
              class="carousel__item col-12 col-sm-11 col-md-6 col-lg-4 col-xl-2 px-3 py-3"
              v-for="(item, index) in hotdogs"
              :key="`hotdog-${index}-${item.idProducto}`"
            >
              <ProductCard
                :id="item.idProducto"
                :picture="item.imagen"
                :title="item.nombreProducto"
                :description="item.descripcion"
                :price="item.precio"
                type="hotdogs"
              />
            </div>
          </div>
        </CarouselComponent>
      </div>
      <div class="d-block d-md-none">
        <div class="carousel carousel--no-mobile">
          <div class="carousel__container">
            <!-- <div class="carousel__well">
						<div class="col-10 col-sm-5 col-md-4 col-lg-3 col-xl-2 px-3 py-3"  v-for="(item, index) in hotdogs" :key="`hotdog-${index}-${item.id}`">
							<HotdogItem :hotdog="item" />
						</div>
					</div> -->
            <div class="carousel__well">
              <div
                class="carousel__item col-12 col-sm-11 col-md-4 col-lg-3 col-xl-2 px-3 py-3"
                v-for="(item, index) in hotdogs"
                :key="`hotdog-2_${index}-${item.idProducto}`"
              >
                <ProductCard
                  :id="item.idProducto"
                  :picture="item.imagen"
                  :title="item.nombreProducto"
                  :description="item.descripcion"
                  :price="item.precio"
                  type="hotdogs"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="v-nav">
        <div class="container d-none d-md-block p-3">
          <div class="d-flex justify-content-between">
            <div class="col-6 col-sm-5 col-md-4 col-lg-3 col-xl-2">
              <router-link :to="{ name: prevStep }" class="btn btn--bold btn--block">
                Atrás
              </router-link>
            </div>
            <div class="col-6 col-sm-5 col-md-4 col-lg-3 col-xl-2">
              <router-link
                :to="{ name: nextStep }"
                class="btn btn--primary btn--bold btn--block"
              >
                Siguiente
              </router-link>
            </div>
          </div>
        </div>
        <footer class="footer">
          <div class="container text-center">
            Todos Los Derechos Reservados {{ year }} Panshito®.
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.alert {
  background-color: #fe2917;
  color: #fff;
  h5 {
    font-size: 0.9rem;
  }
}

.text-bold {
  font-weight: 700;
}
</style>
